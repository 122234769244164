import { Card, Heading, Svg, Input, Button } from 'components'
import { validate, getError, displayErrors } from 'validators/utils'
import logo from 'assets/logo.svg'

export const ThankYou = () => {
	return {
		view({ attrs: { serverErrors } }) {
			return m('.flex.flex-col.justify-center.items-center.bg-black.pt-10.h-screen', [
				m(
					Svg,
					{
						classes: ['w-24'],
					},
					logo
				),
				m(
					Card,
					{
						classes: ['w-4/5', 'max-w-3xl', 'my-3'],
					},
					[
						serverErrors && displayErrors(serverErrors),
						m('p.text-center.text-lg', 'Thank you for completing this form'),
						// m(
						// 	'p.mb-3',
						// 	'BIFA is a community interest company, meaning that we are run not for profit and with all income used to promote, champion and celebrate independent film and filmmakers year-round.'
						// ),
						// m(
						// 	'p.mb-3',
						// 	'We have no core funding and all of our activity is dependent on the ceremony (via ticket sales, entry fees and a loyal group of partners) or on small project funds. Currently that income does not cover the costs of running the organisation and its activity all year round.'
						// ),
						// m(
						// 	'p.mb-3',
						// 	m.trust(
						// 		`If you are able to, we'd be grateful for any <a href="https://www.bifa.film/about/support-bifa/" class="underline" target="_blank">donation</a> to support our work championing independent British film and talent now and in the future.`
						// 	)
						// ),
					]
				),
			])
		},
	}
}
