import { File, Button } from 'components'
import { notification } from 'utils/notifications'
import { uploadFile } from 'api/forms'

export const QuestionUploadPublic = ({ attrs: { formAnswerSetId, question, onGetFormAnswer, onSetFormAnswer } }) => {
	let loading = false
	return {
		view() {
			let files = onGetFormAnswer(question.id) ? onGetFormAnswer(question.id) : []
			if (!Array.isArray(files)) {
				files = [files]
				onSetFormAnswer(question, files)
			}
			return m('.flex.flex-col', { 'data-max': question.maxValue }, [
				m('label.block.text-sm.font-bold.text-gray-700', question.name),
				files.map((file, ix) => {
					return m('.flex.justify-between.mb-1', [
						m('p.border.p-1.rounded.w-full.mr-3.bg-gray-50', file ? file.split('/')[3] : null),
						m(
							Button,
							{
								type: 'button',
								size: 'sm',
								onclick() {
									files.splice(ix, 1)
									onSetFormAnswer(question, files)
								},
							},
							'Remove'
						),
					])
				}),
				files.length < (question.maxValue ? question.maxValue : 1) &&
					m(
						File,
						{
							type: 'file',
							name: `file_${question.id}`,
							loading,
							oninput: (val) => {
								loading = true
								m.redraw()
								if (val) {
									uploadFile(val, question.formId)
										.then((s) => {
											files.push(s)
											onSetFormAnswer(question, files)
											loading = false
										})
										.catch((err) => {
											notification.error({
												title: 'Upload failed',
												description: 'An error occurred while uploading file, please try again',
											})
											loading = false
										})
								}
							},
						},
						loading ? 'Uploading, please wait...' : 'Upload file'
					),
			])
		},
	}
}
